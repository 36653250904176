<template>
  <container>
    <div slot="bottom_box_content">
      <div class="platform-buttom-list">
        <div
          :class="tagType===1? 'bottom-button-active':'bottom-button'"
          @click="handleChangeTag(1)"
        >媒体清单</div>
        <div
          :class="tagType===2? 'bottom-button-active':'bottom-button'"
          @click="handleChangeTag(2)"
        >附加描述清单</div>
      </div>

      <component :is="curBottomComponent"></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    resourceList: () => import('./components/List.vue'),
    additionalList: () => import('./additional/List.vue')
  },
  data () {
    return {
      tagType: 1,
      currentComponent: 'resourceList'
    }
  },
  mounted () {
    this.initComponents()
  },
  destroyed () {
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  },
  methods: {
    handleChangeTag (tag) {
      this.tagType = tag
      switch (this.tagType) {
        case 1:
          this.currentComponent = 'resourceList'
          this.setBottomComponent(this.currentComponent)
          break
        case 2:
          this.currentComponent = 'additionalList'
          this.setBottomComponent(this.currentComponent)
          break
      }
    },
    initComponents () {
      // 清空便签项
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('')
      this.setRightComponent('')

      this.setBottomComponent(this.currentComponent)
    }
  }
}
</script>
